export const paths = {
  public: {
    index: '/',
    login: {
      index: '/login',
      checkToken: '/login/checkToken'
    }
  },
  authenticated: {
    index: '/',
    home: '/home',
    jobs: {
      index: '/jobs',
      new: '/jobs/new',
      history: '/jobs/history',
      jobdetail: '/jobs/:jobType/:jobName/',
      jobeditdetail: '/jobs/:jobType/:jobName/edit',
      jobhistory: '/jobs/:jobType/:jobName/:startDate'
    },
    reports: {
      index: '/reports',
      designer: '/reports/design'
    },
    app: {
      index: '/app',
      users: '/app/users'
    },
    notifications: {
      index: '/notifications',
      notificationdetail: '/notifications/:notificationId'
    }
  },
};