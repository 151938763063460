import React, {FC, ReactNode} from "react";
import { Theme, useMediaQuery} from "@mui/material";
import {styled} from "@mui/material/styles";
import {AppSideNavSection, usePWMNavSections} from "../SideNavConfig";
import {useSettings} from "src/JobsApp/hooks/use-settings";
import PropTypes from "prop-types";
import {NavColor} from "src/JobsApp/types/settings";
import {Navbar} from "src/JobsApp/components/nav/Navbar";
import {SideNav} from "src/JobsApp/components/nav/SideNav";
import {useMobileNav} from '../components/nav/mobile-nav/use-mobile-nav';
import {MobileNav} from '../components/nav/mobile-nav';

export const PortalLayout: React.FC<PortalProps> = (props) => {
  const settings = useSettings();
  const sections = usePWMNavSections();
  return (
    <VerticalLayout sections={sections} navColor={settings.navColor} {...props} />
  )
}

interface PortalProps {}

// ported from layouts/dashboard/vertical-layout/index.tsx
const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const {children, sections, navColor} = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  return (
    <>
      <Navbar onMobileNavOpen={mobileNav.handleOpen}/>
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array
};

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: AppSideNavSection[];
}

const SIDE_NAV_WIDTH: number = 280;

const VerticalLayoutRoot = styled('div')(
  ({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: SIDE_NAV_WIDTH
    }
  })
);

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});