import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import {useRouter} from "src/JobsApp/hooks/use-router";
import {useMounted} from "src/JobsApp/hooks/use-mounted";
import {useSearchParams} from "src/JobsApp/hooks/use-search-params";
import { paths } from "../paths";
import {apiSettings} from '../config';
import {Storage} from '../utils/storage-util';
import {useToast} from '../hooks/use-toast';
import {getFriendlyErrorMessage} from './api-context';
import {CreateTokenDTO} from '../entities/authentication/CreateTokenDTO';
import {LoginRequestDTO} from '../entities/authentication/LoginRequestDTO';
import {CheckSessionActiveResponseDTO} from '../entities/authentication/CheckSessionActiveResponseDTO';
import {DataResponseDTO} from '../entities/app/DataResponseDTO';
import { CreateTokenResponseDTO } from "../entities/authentication/CreateTokenResponseDTO";
import {LoginResponseDTO} from '../entities/authentication/LoginResponseDTO';
import {AccessControl, Roles} from '../entities/authorization/AccessControl';
import {GetAccountResponseDTO} from '../entities/authentication/GetAccountResponseDTO';

const AuthenticationRoute = 'authentication';
const TwelveHours = (new Date()).valueOf() + 1000 * 60 * 60 * 12;
const SESSION_ID = 'pwm_session_id';
export interface IAccount {
  name: string;
  username: string;
  firstName: string;
  lastName: string;
  mail: string;
  photo?: any;
  role: keyof typeof Roles;
  oid: string;
  iat: number;
  exp: number;
}

export interface IAuthContext {
  account?: IAccount;
  expiresAt?: string;
  issuedAt?: string;
  jwt?: string;
  isSignedIn: boolean;
  isInitialized: boolean;
  logout?: () => Promise<void>;
  reAuthJwt?:() => Promise<void>;
  login?: (data: LoginRequestDTO) => Promise<void>;
  createToken?: (params: CreateTokenDTO) => Promise<any>;
  checkToken?: (token: string) => Promise<any>;
  isAdmin?: () => boolean;
}

export const AuthContext = React.createContext<IAuthContext>({isSignedIn: false, isInitialized: false});
AuthContext.displayName = 'AuthContext';
export const AuthProvider: React.FC<{ children: any; }> = (props) => {
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [jwt, setJwt] = React.useState<string | undefined>();
  const [userAccount, setUserAccount] = React.useState<IAccount | undefined>();
  const [expiresAt, setExpiresAt] = React.useState<string | undefined>();
  const [issuedAt, setIssuedAt] = React.useState<string | undefined>();
  const [checked, setChecked] = useState(false);
  
  const router = useRouter();
  const mounted = useMounted();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const toast = useToast();

  const check = useCallback(
    () => {
      if(mounted() && !isSignedIn && checked){
        const searchParams = new URLSearchParams({returnTo: window.location.href}).toString();
        const href = paths.public.login.index + `?${searchParams}`;
        console.log(`redirecting to login screen ${href}`);
        router.replace(href);
      } else {
        setChecked(true);
      }
    },
    [mounted, isSignedIn, checked, router]
  );

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  const getSignedJwt = async (user: IAccount) => {

  }

  const checkSessionActive = async (sessionid: string) => {
    /*
    todo complete this
    const {data: {success, account}} = await axios.get<CheckSessionActiveResponseDTO>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, {
      params:{stillAlive: true},
      headers: {sessionid}
    });
    if(!success && value && value.logout) {
      await value.logout();
    }
     */
    return {success: true, account: DummyUser};
  }
  const checkSession = async () => {
    if (window.location.pathname === paths.public.login.checkToken) {
      console.log('checking token, not checking for active session');
      return;
    }
    // check session key
    const sessionId = Storage.getItem(SESSION_ID);
    console.log(`Found session ${sessionId}`);
    if (sessionId) {
      const {success, account} = await checkSessionActive(sessionId);
      if(success && account){
        setJwt(sessionId);
        setUserAccount({
          role: account.role,
          name: `${account.firstName} ${account.lastName}`,
          mail: account.mail,
          username: account.username,
          firstName: account.firstName,
          lastName: account.lastName,
          oid: account.oid,
          exp: TwelveHours,
          iat: (new Date()).valueOf()
        });
        toast.success('You are logged in');
      }
    }
  }
  useEffect(() => {
    checkSession();
    const timer = setTimeout(() => setIsInitialized(true), 2000);
    return () => clearTimeout(timer);
  }, []);
  
  useEffect(() => {
    const iSL = isInitialized && Boolean(jwt);
    console.log(`Setting logged in state ${iSL}`)
    setIsSignedIn(iSL);
  }, [jwt, isInitialized]);

  const value: IAuthContext = {
    isSignedIn,
    account: userAccount,
    isInitialized,
    expiresAt,
    issuedAt,
    jwt,
    async createToken(params: CreateTokenDTO): Promise<any>{
      setJwt('1234');
      // @ts-ignore
      setUserAccount(DummyUser);
      /*try {
        const {data: {data}} = await axios.post<DataResponseDTO<CreateTokenResponseDTO>>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, params);
        if (data.authPath) {
          try {
            const url = new URL(data.authPath);
            return router.push(`${url.pathname}${url.search}`);
          } catch (e) {
            console.error(e);
          }
        }
      } catch (err) {
        toast.error(getFriendlyErrorMessage(err));
      }*/
    },
    async checkToken(token: string){
      const { data } = await axios.get<LoginResponseDTO>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, {params: {token}});
      console.dir(data);
      if (data.success && data.account) {
        const { account } = data;
        Storage.setItem(SESSION_ID, data.token);
        setJwt(data.token);
        setUserAccount(convertToAccount(account));
      } else {
        setJwt(undefined);
        setUserAccount(undefined);
      }
    },
    async login(params: CreateTokenDTO, redirect?: string){
      // todo this is for when we go with username and password authentication
      const loginParams = {
        email: params.email,
        path: params.path
      };

      const {data: {data}} = await axios.post<DataResponseDTO<LoginResponseDTO>>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, loginParams);
      console.dir(data);
      const {token, success, account} = data;
      if(success && account){
        setJwt(token);
        setUserAccount(convertToAccount(account));
      }
    },
    async logout(){
      await axios.delete(`${apiSettings.baseUrl}/${AuthenticationRoute}`, {headers:{sessionid: jwt}});
      setUserAccount(undefined);
      setJwt(undefined);
      Storage.clear();
      return;
    },
    async reAuthJwt (){if(userAccount) return getSignedJwt(userAccount)},
    isAdmin(){ return AccessControl.isAdminRole(userAccount?.role)}
  };

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
};

const convertToAccount = (account: GetAccountResponseDTO) => ({
  role: account.role,
  name: `${account.firstName} ${account.lastName}`,
  mail: account.emailAddress,
  username: account.emailAddress,
  firstName: account.firstName,
  lastName: account.lastName,
  oid: account.id + '',
  exp: TwelveHours,
  iat: (new Date()).valueOf()
})

const DummyUser = {
  role: 'Admin' as 'Admin',
  firstName: 'Kevin',
  lastName: 'Splittgerber',
  name: 'Kevin Splittgerber',
  username: 'KSplittgerber1@sandi.net',
  mail: 'ksplittgerber1@sandi.net',
  iat: 123,
  exp: 231,
  oid: 'str'
}