import {tokens} from '../tokens';

const interfaces = {
  [tokens.interface.list]: 'List',
  [tokens.interface.add]: 'Add',
  [tokens.interface.active]: 'Active',
  [tokens.interface.view]: 'View',
  [tokens.interface.details]: 'Details',
};
const nav = {
  [tokens.nav.account]: 'Account',
  [tokens.nav.home]: 'Home',
  [tokens.nav.jobs]: 'Jobs',
  [tokens.nav.history]: 'History'
}

const common = {
  [tokens.common.languageChanged]: 'Language changed',
};
const jobs = {
  [tokens.app.jobs.history]: 'Job History',
  [tokens.app.jobs.create]: 'Create Job',
  [tokens.app.jobs.edit]: 'Job Edit',
  [tokens.app.jobs.detail]: 'Job Detail',
  [tokens.app.jobs.detailhistory]: 'Job History'
};
const app = {
  [tokens.app.appname]: 'FPC Background Jobs Server',
  [tokens.app.login]: 'Login',
  ...jobs
}
export const en = {
  ...nav,
  ...common,
  ...app,
  ...interfaces
};