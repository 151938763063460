import {ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppData} from './hooks/use-app-data';
import {tokens} from './locales/tokens';
import {paths} from './paths';

export interface PWMSideNavItem {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: PWMSideNavItem[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface AppSideNavSection {
  items: PWMSideNavItem[];
  subheader?: string;
}

export const usePWMNavSections = () => {
  const {t} = useTranslation();
  const appData = useAppData();
  return useMemo((): AppSideNavSection[] => {
    return [{
      subheader: `${t(tokens.app.appname)} ${appData?.environment}`,
      items: [{
        title: t(tokens.nav.home),
        path: paths.authenticated.home
      }]
    },
      {
        subheader: `${t(tokens.nav.jobs)}`,
        items: [{
          title: t(tokens.interface.list),
          path: paths.authenticated.jobs.index
        }, {
          title: t(tokens.interface.add)!,
          path: paths.authenticated.jobs.new
        }]
      }, {
        subheader: t(tokens.nav.history)!,
        items: [{
          title: t(tokens.interface.list),
          path: paths.authenticated.jobs.history
        }]
      }];
  }, [t, appData?.environment]);
};