import {lazy, Suspense} from 'react';
import {RouteObject} from 'react-router';
import {Outlet} from 'react-router-dom';
import {PortalLayout} from './layouts/PortalLayout';
import Error404Page from 'src/JobsApp/pages/RouteNotFound';
import {paths} from './paths';
import {GuestGuard} from 'src/JobsApp/guards/guest-guard';
import AuthGuard from 'src/JobsApp/guards/auth-guard';
import {PublicLayout} from './layouts/PublicLayout';

const authenticatedElements = (
  <AuthGuard>
    <PortalLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </PortalLayout>
  </AuthGuard>
);

// UN-AUTHENTICATED PAGES
// ====================================
const LoginPage = lazy(() => import('./pages/LoginPage'));
const CheckTokenPage = lazy(() => import('./pages/login/CheckTokenScreen'));

// AUTHENTICATED PAGES
// ====================================
// Main Application Pages
const Home = lazy(() => import('src/JobsApp/pages/HomePage'));

const JobsListPage = lazy(() => import('src/JobsApp/pages/jobs/JobListPage'));
const JobDetailPage = lazy(() => import('src/JobsApp/pages/jobs/detail/JobDetailPage'));
const JobNewPage = lazy(() => import('src/JobsApp/pages/jobs/new/CreateNewJobPage'));
const JobEditPage = lazy(() => import('src/JobsApp/pages/jobs/edit/EditJobPage'));
const JobHistoryPage = lazy(() => import('src/JobsApp/pages/jobs/history/JobHistoryListPage'));
const JobHistoryDetailPage = lazy(() => import('src/JobsApp/pages/jobs/history/detail/JobHistoryDetailPage'));

export const Routes: RouteObject[] = [
  {
    path: paths.authenticated.index,
    element: authenticatedElements,
    children: [
      {
        path: paths.authenticated.home,
        element: <Home />
      }]
  },
  {
    path: paths.authenticated.jobs.index,
    element: authenticatedElements,
    children: [{
      index: true,
      element: <JobsListPage />
    },
      {
        path: paths.authenticated.jobs.jobdetail,
        element: <JobDetailPage />
      }, {
        path: paths.authenticated.jobs.new,
        element: <JobNewPage />,
      }, {
        path: paths.authenticated.jobs.jobeditdetail,
        element: <JobEditPage />
      }, {
        path: paths.authenticated.jobs.history,
        element: <JobHistoryPage />
      }, {
        path: paths.authenticated.jobs.jobhistory,
        element: <JobHistoryDetailPage />
      }
    ]
  },
  {
    path: paths.public.login.index,
    element:
      (
        <GuestGuard>
          <PublicLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </PublicLayout>
        </GuestGuard>
      ),
    children:
      [{
        index: true,
        element: <LoginPage />
      }, {
        path: paths.public.login.checkToken,
        element: <CheckTokenPage />
      }]
  },
  {
    path: '*',
    element:
      <Error404Page />
  }
];