import React from 'react';
import {TModes} from 'api/services/Application';
import {useApi} from 'src/JobsApp/hooks/use-api';

// init local storage
const defaultValue: IAppDataContext = {
  environment: 'DEVELOPMENT', apiVersion: ''
}

export const AppDataContext = React.createContext<IAppDataContext>(defaultValue);
AppDataContext.displayName = 'AppDataContext';

export const AppDataProvider: React.FC<{children: any}> = (props) => {
  const api = useApi();
  const {environment, apiVersion} = api!;

  const value: IAppDataContext = {
    environment,
    apiVersion
  }

  return (
    <AppDataContext.Provider value={value}>{props.children}</AppDataContext.Provider>
  )
}

interface IAppDataContext {
  environment: TModes;
  apiVersion: string;
}
