
export class AccessControl {
  static isAdminRole(role?: keyof typeof Roles): boolean {
    return Object.keys(Roles).includes(role+'');
  }

}

export const Roles = {
  Admin: 'admin'
}