import React from 'react';
import {
  Box, Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover, Stack,
  Typography
} from "@mui/material";
import {useAuth} from "src/JobsApp/hooks/use-auth";
import {ThemeSelector} from "../settings/ThemeSelector";
import UserAvatar from "../settings/UserAvatar";


const AccountPopover: React.FC<IAccountPopoverProps> = (props) => {
  const {anchorEl, onClose, open, ...other} = props;
  const {account, logout} = useAuth();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted={true}
      disableScrollLock={true}
      onClose={onClose}
      open={!!open}
      PaperProps={{sx: {width: 385}}}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2
        }}
      >
        <Stack>
          <Box sx={{display: 'flex'}}>
            <ListItemIcon>
              <UserAvatar/>
            </ListItemIcon>
            <MenuItem sx={{pt: 0, mt: 0}}>
              <ListItemText sx={{textAlign: 'right'}}>
                <Typography variant="body1">
                  {account?.name}
                </Typography>
              </ListItemText>
            </MenuItem>
          </Box>
          <MenuItem component="a">
            <ListItemIcon sx={{m: 0, p: 0}}>
              <Button onClick={logout} variant="text">Logout</Button>
            </ListItemIcon>
            <ListItemText sx={{textAlign: 'right'}}>
              {/*<PWMLink
                href="/settings/profile" todo implement this page
              >*/}
               <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {account?.username}
                </Typography>
              {/*</PWMLink>*/}
            </ListItemText>
          </MenuItem>
        </Stack>
      </Box>
      <Divider/>
      <Stack
        sx={{p: 2, m: 0}}
      >
        <ThemeSelector/>
      </Stack>
    </Popover>
  )
};

export default AccountPopover;

interface IAccountPopoverProps {
  children?: React.ReactNode;
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}