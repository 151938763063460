export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  app: {
    appname: 'app.appname',
    login: 'app.login',
    jobs: {
      history: 'jobs.app.jobs.history',
      create: 'jobs.app.jobs.create',
      detailhistory: 'jobs.app.jobs.detailhistory',
      edit: 'app.jobs.edit',
      detail: 'app.jobs.detail'
    }
  },
  interface: {
    list: 'interface.list',
    active: 'interface.active',
    add: 'interface.add',
    edit: 'interface.edit',
    details: 'interface.details',
    view: 'interface.view'
  },
  nav: {
    home: 'nav.home',
    account: 'nav.account',
    jobs: 'nav.jobs',
    history: 'nav.history'
  }
};
