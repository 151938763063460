import type {FC} from 'react';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import {Badge, IconButton, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/JobsApp/hooks/use-popover';
import {NotificationsPopover} from './NotificationsPopover'


export const NotificationsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
        >
          <Badge
            color="error"
            badgeContent={0}
          >
            <SvgIcon>
              <Bell01Icon/>
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={[]}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
